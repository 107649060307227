import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

const EquipeStatSender = () => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });

      // Supposons que nous voulons lire la première feuille
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(worksheet);
      console.log(data);
    };

    reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  return (
    <div
      className="border-2 text-white 
    mt-[120px] mx-auto min-h-[80vh]
    bg-custom-gradient
    w-[75%] rounded-xl"
    >
      <div className="w-full h-full p-8 max-w-4xl">
        <div
          className="bg-quadrary hover:bg-thirdary text-center border-dashed border-2 py-4 px-6 rounded border-white duration-200"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Déposez le fichier ici ...</p>
          ) : (
            <p>
              Déposez vos fichier Excel "<strong>ici</strong>"
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EquipeStatSender;
