import React from "react";
import { NavLink } from "react-router-dom";
import { IoBasketballSharp } from "react-icons/io5";
// import { TbPlayBasketball } from "react-icons/tb";
import { RiTeamFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa6";

const NavBar = () => {
  const navItems = [
    { link: "/Home", icon: <IoBasketballSharp />, text: "Statistique" },
    { link: "/Equipe", icon: <RiTeamFill />, text: "Equipe" },
    {
      link: "/Login",
      icon: <FaUser className="text-6xl" />,
      text: "Connexion",
    },
    // { icon: <TbPlayBasketball />, text: "Play Basketball" },
  ];

  const NavBarIcon = ({ link, icon, text, onClick }) => {
    const WrapperComponent = link ? NavLink : "div";

    return (
      <WrapperComponent
        to={link}
        className="NavBar-icon group"
        onClick={onClick}
      >
        {icon}
        <span className="NavBar-tooltip group-hover:scale-100">{text}</span>
      </WrapperComponent>
    );
  };

  return (
    <div className="absolute top-0 left-0 w-screen h-30 flex flex-row shadow-lg shadow-[#033a07]">
      {navItems.map((item, index) => (
        <NavBarIcon
          key={index}
          link={item.link}
          icon={item.icon}
          text={item.text}
          onClick={item.onClick}
        />
      ))}
    </div>
  );
};

export default NavBar;
