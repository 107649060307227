import React, { useState } from "react";
import { FaUserShield } from "react-icons/fa6";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ajoutez votre logique de gestion de la soumission ici, par exemple, une requête API pour vérifier les informations de connexion
    console.log("Email:", email);
    console.log("Password:", password);
  };

  return (
    <div className="flex flex-col w-[95%] items-center justify-center">
      <FaUserShield
        className="relative flex items-center justify-center 
        mx-auto mt-12
        text-white text-[10rem] cursor-pointer"
      />
      <h1 className="text-white font-bold text-4xl text-center">Login Here</h1>
      <div className="max-w-md mt-6 w-full space-y-8">
        <div></div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div>
            <label htmlFor="email" className="sr-only">
              Adresse email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none bg-thirdary border-b-2 text-white placeholder-gray-400  relative w-full px-3 py-2 sm:text-sm focus:outline-none"
              placeholder="Adresse email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Mot de passe
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="appearance-none bg-thirdary border-b-2 text-white placeholder-gray-400 relative w-full px-3 py-2 sm:text-sm focus:outline-none"
              placeholder="Mot de passe"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <br />
          <div>
            <button
              type="submit"
              className="border rounded-full bg-thirdary w-full text-white text-lg p-1 mt-[-1%]"
            >
              Login
            </button>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href="/Login"
                className="font-medium text-gray-400 hover:text-gray-300"
              >
                Mot de passe oublié ?
              </a>
            </div>
          </div>
          <br />
        </form>
      </div>
    </div>
  );
}
