import React, { useState } from "react";

const IncrementDecrementButton = ({
  state,
  stateSetter,
  placeholder,
  name,
}) => {
  const handleIncrement = () => {
    const newValue = (state || 0) + 1;
    if (name.endsWith("mrq")) {
      // Ajouter 1 a Try
    }
    stateSetter(newValue);
  };

  const handleDecrement = () => {
    const newValue = (state || 0) - 1;
    if (newValue >= 0) {
      stateSetter(newValue);
    }
  };

  return (
    <div className="flex flex-row justify-center m-[-10%]">
      <button
        className="select-none justify-center border-none bg-secondary inline-block text-white w-5 cursor-pointer mx-1 rounded text-center h-full"
        onClick={handleDecrement}
      >
        -
      </button>
      <input
        type="number"
        placeholder={placeholder}
        className="lf1 text-center"
        name={name}
        value={state || 0}
        onChange={(e) => {
          const num = parseInt(e.target.value, 10);
          stateSetter(num >= 0 ? num : 0);
        }}
        min="0"
      />
      <button
        className="select-none justify-center border-none bg-secondary inline-block text-white w-5 cursor-pointer mx-1 rounded text-center h-full"
        onClick={handleIncrement}
      >
        +
      </button>
    </div>
  );
};

const PlayerRow = ({ id, stats, onUpdateStats, onDelete }) => {
  const [statsState, setStatsState] = useState(
    stats || {
      firstName: "",
      LfMrq: 0,
      LfTry: 0,
      Sh2Mrq: 0,
      Sh2Try: 0,
      Sh3Mrq: 0,
      Sh3Try: 0,
      steal: 0,
      rebound: 0,
      assist: 0,
      foul: 0,
      turnover: 0,
    }
  );
  const handleScoreUpdate = (value, statType) => {
    // Mettre à jour l'état local des stats
    const updatedStats = { ...statsState, [statType]: value };
    setStatsState(updatedStats);

    // Appeler la fonction passée par le composant parent pour mettre à jour l'état global
    onUpdateStats(id, updatedStats);
  };

  const handleFirstNameChange = (e) => {
    const updatedStats = { ...statsState, firstName: e.target.value };
    setStatsState(updatedStats);
    onUpdateStats(id, updatedStats);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <tr>
      <td className="td-cell">
        <input
          type="text"
          className="display-block m-0 text-center"
          value={statsState.firstName}
          onChange={handleFirstNameChange}
          placeholder="prenom"
          name="prenom"
        />
      </td>

      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.LfMrq}
          stateSetter={(value) => handleScoreUpdate(value, "LfMrq")}
          placeholder="lf"
          name="lfmrq"
        />
        <div className="pt-5"></div>
        <IncrementDecrementButton
          state={statsState.LfTry}
          stateSetter={(value) => handleScoreUpdate(value, "LfTry")}
          placeholder="lf"
          name="lftry"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.Sh2Mrq}
          stateSetter={(value) => handleScoreUpdate(value, "Sh2Mrq")}
          placeholder="sh"
          name="sh2mrq"
        />
        <div className="pt-5"></div>
        <IncrementDecrementButton
          state={statsState.Sh2Try}
          stateSetter={(value) => handleScoreUpdate(value, "Sh2Try")}
          placeholder="sh"
          name="sh2try"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.Sh3Mrq}
          stateSetter={(value) => handleScoreUpdate(value, "Sh3Mrq")}
          placeholder="sh"
          name="sh3mrq"
        />
        <div className="pt-5"></div>
        <IncrementDecrementButton
          state={statsState.Sh3Try}
          stateSetter={(value) => handleScoreUpdate(value, "Sh3Try")}
          placeholder="sh"
          name="sh3try"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.steal}
          stateSetter={(value) => handleScoreUpdate(value, "steal")}
          placeholder="intercep"
          name="intercep"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.rebound}
          stateSetter={(value) => handleScoreUpdate(value, "rebound")}
          placeholder="rebond"
          name="rebond"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.assist}
          stateSetter={(value) => handleScoreUpdate(value, "assist")}
          placeholder="passed"
          name="passed"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.foul}
          stateSetter={(value) => handleScoreUpdate(value, "foul")}
          placeholder="faute"
          name="faute"
        />
      </td>
      <td className="td-cell">
        <IncrementDecrementButton
          state={statsState.turnover}
          stateSetter={(value) => handleScoreUpdate(value, "turnover")}
          placeholder="ballep"
          name="ballep"
        />
      </td>
      <td className="td-cell">
        <button
          className="text-white select-none cursor-pointer text-center m-auto border bg-primary border-secondary p-1 rounded hover:bg-quadrary duration-150"
          type="button"
          onClick={handleDelete}
        >
          Supprimer
        </button>
      </td>
    </tr>
  );
};

export default PlayerRow;
