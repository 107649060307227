import React from "react";
import NavBar from "../components/NavBar";
import EquipeStatSender from "../components/EquipeStatSender";

const Equipe = () => {
  return (
    <div>
      <NavBar />
      <div>
        <EquipeStatSender />
      </div>
    </div>
  );
};

export default Equipe;
