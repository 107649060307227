import React from "react";

const AddPlayer = ({ onAddPlayer }) => {
  return (
    <div>
      <button
        onClick={onAddPlayer}
        className="bg-primary hover:bg-quadrary py-2 px-4 rounded border border-secondary duration-150"
      >
        Ajouter un joueur
      </button>
    </div>
  );
};

export default AddPlayer;
