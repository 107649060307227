import React from "react";

const SavePlayer = ({ onSavePlayer }) => {
  return (
    <div>
      <button
        onClick={onSavePlayer}
        className="bg-primary hover:bg-quadrary py-2 px-4 rounded border border-secondary duration-150"
      >
        Enregistrer
      </button>
    </div>
  );
};

export default SavePlayer;
