import React from "react";
import NavBar from "../components/NavBar";
import Tableau from "../components/Tableau";

const Home = () => {
  return (
    <div>
      <NavBar />
      <div className="pt-24">
        <p className="text-4xl text-white text-center mt-5 font-bold">
          Statistiques des joueurs
        </p>
        <Tableau />
      </div>
    </div>
  );
};

export default Home;
