import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Cadre = () => {
  const location = useLocation();

  const [isActive1, setIsActive1] = useState(location.pathname === "/Login");
  const [isActive2, setIsActive2] = useState(location.pathname === "/Register");

  useEffect(() => {
    setIsActive1(location.pathname === "/Login");
    setIsActive2(location.pathname === "/Register");
  }, [location.pathname]);

  return (
    <div className="bg-primary text-xl mt-6 flex">
      <NavLink to="/Login">
        <button
          className={`w-full text-white border-b-2 px-12 py-4 md:px-14 md:py-5
      bg-gradient-to-r from-primary ${isActive1 ? "to-secondary" : ""}
      transition-all duration-200`}
        >
          Login
        </button>
      </NavLink>
      <NavLink to="/Register">
        <button
          className={`w-full text-white border-b-2 px-12 py-4 md:px-14 md:py-5
      bg-gradient-to-r ${isActive2 ? "from-secondary" : ""} to-primary
      transition-all duration-200`}
        >
          Register
        </button>
      </NavLink>
    </div>
  );
};

export default Cadre;
