import React from "react";
import NavBar from "../components/NavBar";
import Cadre from "../components/Cadre";
import Register from "../components/Register";

const ConnexionRegister = () => {
  return (
    <div>
      <NavBar />
      <div className="pt-[130px]">
        <div className="flex flex-col items-center justify-center min-h-[80vh] border bg-thirdary w-[90%] mx-auto rounded-xl lg:w-[50%] xl:w-[30%]">
          <Cadre bgColor1="primary" bgColor2="secondary" />
          <Register />
        </div>
      </div>
    </div>
  );
};

export default ConnexionRegister;
