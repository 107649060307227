import React, { useState, useEffect } from "react";
import PlayerRow from "./PlayerRow";
import AddPlayer from "./AddPlayer";
import SavePlayer from "./SavePlayer.js";
import * as XLSX from "xlsx";

const Tableau = () => {
  const [players, setPlayers] = useState(() => {
    // Chargement des joueurs depuis le localStorage au montage du composant
    const savedPlayers = localStorage.getItem("players");
    return savedPlayers ? JSON.parse(savedPlayers) : [];
  });
  const [idCounter, setIdCounter] = useState(() => {
    // Chargement de l'idCounter depuis le localStorage
    const savedIdCounter = localStorage.getItem("idCounter");
    return savedIdCounter ? JSON.parse(savedIdCounter) : 1;
  });

  // Ajouter un joueur
  const addPlayer = () => {
    const newId = idCounter;
    const newPlayers = [
      ...players,
      {
        id: newId,
        stats: {
          firstName: "",
          freeThrow: 0,
          twoPoints: 0,
          threePoints: 0,
          steal: 0,
          rebound: 0,
          assist: 0,
          foul: 0,
          turnover: 0,
        },
      },
    ]; // Assurez-vous d'ajouter toutes les données nécessaires pour un joueur ici.
    setPlayers(newPlayers);
    setIdCounter(newId + 1);
  };

  // Sauvegarder les stats dans Excel
  const savePlayer = () => {
    const playerData = players.map((player) => ({
      Prénom: player.stats.firstName || "",
      "Lf mrq": player.stats.LfMrq || 0,
      "Lf try": player.stats.LfTry || 0,
      "sh2 mrq ": player.stats.Sh2Mrq || 0,
      "sh2 try ": player.stats.Sh2Try || 0,
      "sh3 mrq ": player.stats.Sh3Mrq || 0,
      "sh3 try ": player.stats.Sh3Try || 0,
      InterC: player.stats.steal || 0,
      Rebond: player.stats.rebound || 0,
      "Passe D": player.stats.assist || 0,
      Faute: player.stats.foul || 0,
      BP: player.stats.turnover || 0,
    }));

    var date = new Date();
    const dateString =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    const ws = XLSX.utils.json_to_sheet(playerData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Players");
    XLSX.writeFile(wb, "Stats_" + dateString + ".xlsx");
  };

  const updatePlayerStats = (playerId, newStats) => {
    const updatedPlayers = players.map((player) =>
      player.id === playerId ? { ...player, stats: newStats } : player
    );
    setPlayers(updatedPlayers);
    localStorage.setItem("players", JSON.stringify(updatedPlayers)); // Sauvegarder dans localStorage
  };

  const handleDelete = (playerId) => {
    const filteredPlayers = players.filter((player) => player.id !== playerId);
    setPlayers(filteredPlayers);
    localStorage.setItem("players", JSON.stringify(filteredPlayers)); // Sauvegarder dans localStorage
  };

  useEffect(() => {
    if (players.length > 0) {
      localStorage.setItem("players", JSON.stringify(players));
    }

    localStorage.setItem("idCounter", JSON.stringify(idCounter));
  }, [players, idCounter]);

  return (
    <div className="min-w-16">
      <div className="text-white font-bold flex justify-center gap-4 mt-4">
        <AddPlayer onAddPlayer={addPlayer} />
        <SavePlayer onSavePlayer={savePlayer} />
      </div>
      <table className="mx-auto border-collapse mt-5">
        <thead>
          <tr className="table-row">
            <th className="th-cell">Prénom</th>
            <th className="th-cell">Lancer franc</th>
            <th className="th-cell">2 points</th>
            <th className="th-cell">3 points</th>
            <th className="th-cell">Interception</th>
            <th className="th-cell">Rebond</th>
            <th className="th-cell">Passe D</th>
            <th className="th-cell">Faute</th>
            <th className="th-cell">Balle Perdu</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {players.map((player, index) => (
            <PlayerRow
              key={player.id}
              id={player.id}
              stats={player.stats}
              onUpdateStats={updatePlayerStats}
              onDelete={handleDelete}
            />
          ))}
        </tbody>
        <div className="pt-10"></div>
      </table>
    </div>
  );
};

export default Tableau;
